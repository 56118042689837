import React, {useEffect, useState} from 'react';

const Feed = () => {
    const [posts, setPosts] = useState([]);
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [username, setUsername] = useState("");

    useEffect(() => {
        // load posts
        loadPosts();

    }, []);

    const loadPosts = async() => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };
        let res = await fetch('https://holy-smoke-849c.ragarwal84.workers.dev/posts', requestOptions);
        res = await res.json();
        res.reverse();
        setPosts(res);
    }

    const submitPost = async(event) => {
        event.preventDefault();
        let data = {title: title, username: username, content: content};
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data)
        };
        await fetch('https://holy-smoke-849c.ragarwal84.workers.dev/posts', requestOptions);
        window.location.reload();
    }

    return(
        <div>
            <form onSubmit={submitPost}>
                <input labelHidden placeholder="Title" className="form-input mt-8" onChange={event => setTitle(event.target.value)} value={title} required/>
                <input labelHidden placeholder="Content" className="form-input mt-8" onChange={event => setContent(event.target.value)} value={content} required/>
                <input labelHidden placeholder="Username" className="form-input mt-8" onChange={event => setUsername(event.target.value)} value={username} required/>
                <input type="submit" value="Submit" className="form-input mt-8"/>                
            </form>

            <div style={{overflowY: "scroll", display: "flex", alignItems: "center", flexDirection: "column", maxHeight: 1500}}>
                {posts.map(post => <Post data={post}/>)}
            </div>
        </div>
    );
}

const Post = (props) => {
    let post = props.data;
    return(
        <div style={{textAlign: "left", width: "30%", padding: 32, margin: 8, borderRadius: 4, boxShadow: "1px 3px 10px #9E9E9E"}}>
            <h3>{post.title}</h3>
            <h5>{post.content}</h5>
            <p>by {post.username}</p>
        </div>
    );
}

export default Feed;